import { CloseOutlined, DownloadOutlined } from "@mui/icons-material";
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { format, isValid, parseISO } from "date-fns";
import { toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { CardContent } from "./CardContent";
import { useGetSingleMembersQuery } from "../../../store/members/membersApis";
// import "./member-card.css";

export const MemberCard = ({ data, show, close }) => {
    const { data: memberData } = useGetSingleMembersQuery({ _id: data?._id }, { skip: !data?._id });
    // const [memberData, setMemberData] = useState();
    const [cardData, setCardData] = useState(null);
    const [prePrint, setPrePrint] = useState(false);
    const frontRef = useRef(null);
    const backRef = useRef(null);

    const handleCardGenerate = (type) => {
        if (type === "primary") {
            let payload = {
                name: data?.name,
                mobile: data?.mobile,
                email: data?.email,
                chss_number: data?.chss_number,
                dob: data?.dob,

                membership_no: `P-${data?.member_id}`,
                membership_type: "Primary",
                membership_plan: data?.current_plan?.plan_name,
                relation_with_primary: "Self",
            };

            let bookingData = [];
            memberData?.bookings &&
                memberData?.bookings.map((obj, index) => {
                    if (obj?.primary_eligible) {
                        const [day, month, year] = obj?.fees_breakup?.end_date.split("/").map(Number);
                        const expirePlanDate = new Date(year, month - 1, day);
                        bookingData.push({
                            id: index + 1,
                            activity: obj?.activity_id?.name,
                            location: obj?.batch?.location_id?.title,
                            sublocation: obj?.batch?.sublocation_id?.title,
                            category: obj?.batch?.category_id?.title,
                            sub_category: obj?.batch?.subcategory_name,
                            valid_upto: isValid(expirePlanDate) ? format(expirePlanDate, "MMM, yyyy") : "-",
                            batch_name: obj?.batch?.batch_name,
                            is_non_dependent: obj.chss_number,
                        });
                    }
                });

            payload.bookings = bookingData;

            setCardData(payload);
        } else {
            let familyData = data?.family_details.find((obj) => obj._id === type);
            let index = data?.family_details.findIndex((obj) => obj._id === type);
            let payload = {
                name: familyData?.name,
                mobile: familyData?.mobile || data?.mobile || "-",
                email: familyData?.email || data?.email || "-",
                chss_number: familyData?.card_number || "-",
                dob: familyData?.dob || "-",
                is_dependent: familyData?.is_dependent,

                membership_no: `S${index + 1}-${data?.member_id}`,
                membership_type: "Secondary",
                membership_plan: data?.current_plan?.plan_name,
                relation_with_primary: familyData?.relation || "-",
            };

            let bookingData = [];
            memberData?.bookings &&
                memberData?.bookings.map((obj, index) => {
                    if (!obj?.primary_eligible) {
                        if (
                            obj?.family_member &&
                            obj?.family_member.length > 0 &&
                            obj?.family_member[0]?._id === familyData?._id
                        ) {
                            const [day, month, year] = obj?.fees_breakup?.end_date.split("/").map(Number);
                            const expirePlanDate = new Date(year, month - 1, day);
                            bookingData.push({
                                id: index + 1,
                                activity: obj?.activity_id?.name,
                                location: obj?.batch?.location_id?.title,
                                sublocation: obj?.batch?.sublocation_id?.title,
                                category: obj?.batch?.category_id?.title,
                                sub_category: obj?.batch?.subcategory_name,
                                valid_upto: isValid(expirePlanDate) ? format(expirePlanDate, "MMM, yyyy") : "-",
                                batch_name: obj?.batch?.batch_name,
                            });
                        }
                    }
                });

            payload.bookings = bookingData;

            setCardData(payload);
        }
    };

    const onButtonClickFront = useCallback(() => {
        if (frontRef.current === null) {
            return;
        }

        toPng(frontRef.current, { width: 1247, height: 1748 })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = cardData?.membership_no + "-front-card.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }, [frontRef, cardData]);

    const onButtonClickBack = useCallback(() => {
        if (backRef.current === null) {
            return;
        }

        toPng(backRef.current, { width: 1247, height: 1748 })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = cardData?.membership_no + "-back-card.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }, [backRef, cardData]);

    return (
        <Dialog
            open={show}
            onClose={() => {
                setCardData(null);
                close();
            }}
            maxWidth="sm"
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ display: "flex", justifyContent: "space-between" }}>
                    Member Card
                </Typography>
                <IconButton onClick={() => [close(), setCardData(null)]}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Generate Card For</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => handleCardGenerate(e.target.value)}
                    >
                        <FormControlLabel
                            value={"primary"}
                            control={<Radio />}
                            label={`${data?.name} (Primary) P-${data?.member_id}`}
                        />
                        {data?.family_details?.map((item, index) => (
                            <FormControlLabel
                                value={`${item?._id}`}
                                control={<Radio />}
                                label={`${item?.name} (Secondary) S${index + 1}-${data?.member_id}`}
                            />
                        ))}
                    </RadioGroup>
                    <FormLabel id="demo-radio-buttons-group-label">Printing Type</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="normal"
                        name="radio-buttons-group"
                        onChange={(e) => setPrePrint(e.target.value === "preprint")}
                    >
                        <FormControlLabel value={"normal"} control={<Radio />} label={`Normal Print`} />
                        <FormControlLabel value={"preprint"} control={<Radio />} label={`Pre-Print`} />
                    </RadioGroup>
                </FormControl>
                <div className="card-wrapper-container">
                    <div className="card-wrapper">
                        <CardContent cardData={cardData} prePrint={prePrint} />
                    </div>
                </div>
                <div style={{ opacity: 0, height: 0, overflow: "hidden" }}>
                    <div
                        className="main"
                        ref={frontRef}
                        style={{ backgroundColor: prePrint ? "transparent" : "#e5f4fd" }}
                    >
                        <div className="card">
                            <div className="header" style={{ opacity: prePrint ? 0 : 1 }}>
                                <img src="/assets/images/logo-icon.png" alt="logo" width="70" />
                                <h1>ANUSHAKTINAGAR SPORTS MANAGEMENT COMMITTEE (ASMC)</h1>
                            </div>
                            <div className="profile">
                                <h2 style={{ opacity: prePrint ? 0 : 1 }}>Member Profile</h2>
                                <div className="profile-details">
                                    <table>
                                        <tr>
                                            <td
                                                style={{
                                                    width: "260px",
                                                    height: "10rem",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                    color: prePrint ? "transparent" : "#000",
                                                    backgroundColor: prePrint ? "transparent" : "",
                                                }}
                                            >
                                                Name
                                            </td>
                                            <td
                                                style={{
                                                    height: "10rem",
                                                    lineBreak: "anywhere",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                }}
                                            >
                                                {cardData?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    width: "260px",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                    color: prePrint ? "transparent" : "#000",
                                                    backgroundColor: prePrint ? "transparent" : "",
                                                    height: "7.2rem",
                                                }}
                                            >
                                                Mobile No.
                                            </td>
                                            <td
                                                style={{
                                                    lineBreak: "anywhere",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                }}
                                            >
                                                {cardData?.mobile}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    width: "260px",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                    backgroundColor: prePrint ? "transparent" : "",
                                                    height: "7.2rem",
                                                    color: prePrint ? "transparent" : "#000",
                                                }}
                                            >
                                                Email ID
                                            </td>
                                            <td
                                                style={{
                                                    lineBreak: "anywhere",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                }}
                                            >
                                                {cardData?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    width: "260px",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                    backgroundColor: prePrint ? "transparent" : "",
                                                    height: "7.2rem",
                                                    color: prePrint ? "transparent" : "#000",
                                                }}
                                            >
                                                CHSS / ID
                                            </td>
                                            <td
                                                style={{
                                                    lineBreak: "anywhere",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                }}
                                            >
                                                {cardData?.chss_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    width: "260px",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                    backgroundColor: prePrint ? "transparent" : "",
                                                    height: "7.2rem",
                                                    color: prePrint ? "transparent" : "#000",
                                                }}
                                            >
                                                DOB
                                            </td>
                                            <td
                                                style={{
                                                    lineBreak: "anywhere",
                                                    border: "2px solid",
                                                    borderColor: prePrint ? "transparent" : "#4e889b",
                                                }}
                                            >
                                                {cardData?.dob
                                                    ? handleDateTimeDefault(cardData?.dob, "dd/MM/yyyy")
                                                    : "-"}
                                            </td>
                                        </tr>
                                    </table>
                                    <div
                                        className="profile-photo"
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                        }}
                                    >
                                        <div className="photo"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="membership-details">
                            <h2 style={{ opacity: prePrint ? 0 : 1 }}>Membership Details</h2>
                            <div className="membership-table">
                                <table>
                                    <tr>
                                        <td
                                            style={{
                                                border: "2px solid",
                                                borderColor: prePrint ? "transparent" : "#4e889b",
                                                backgroundColor: prePrint ? "transparent" : "",
                                                color: prePrint ? "transparent" : "#000",
                                            }}
                                        >
                                            Membership No.
                                        </td>
                                        <td
                                            style={{
                                                minWidth: "700px",
                                                border: "2px solid",
                                                borderColor: prePrint ? "transparent" : "#4e889b",
                                            }}
                                        >
                                            {cardData?.membership_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                border: "2px solid",
                                                borderColor: prePrint ? "transparent" : "#4e889b",
                                                backgroundColor: prePrint ? "transparent" : "",
                                                color: prePrint ? "transparent" : "#000",
                                            }}
                                        >
                                            Membership Type
                                        </td>
                                        <td
                                            style={{
                                                minWidth: "700px",
                                                border: "2px solid",
                                                borderColor: prePrint ? "transparent" : "#4e889b",
                                            }}
                                        >
                                            {cardData?.membership_type}{" "}
                                            <span style={{ color: "red", fontWeight: "bold" }}>
                                                {cardData?.chss_number === "NON CHSS" ? "(Non Dependent)" : null}
                                            </span>
                                            {cardData?.membership_type === "Secondary" && (
                                                <span style={{ color: "red", fontWeight: "bold" }}>
                                                    {cardData?.is_dependent ? null : "(Non Dependent)"}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                opacity: prePrint ? 0 : 1,
                                                border: prePrint ? "none" : "2px solid #4e889b",
                                                padding: prePrint ? "16px" : "15px",
                                                color: prePrint ? "transparent" : "#000",
                                            }}
                                        >
                                            Membership Plan
                                        </td>
                                        <td
                                            style={{
                                                minWidth: "700px",
                                                border: prePrint ? "none" : "2px solid #4e889b",
                                                padding: prePrint ? "16px" : "15px",
                                            }}
                                        >
                                            {cardData?.membership_plan}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                opacity: prePrint ? 0 : 1,
                                                border: prePrint ? "none" : "2px solid #4e889b",
                                                padding: prePrint ? "16px" : "15px",
                                                color: prePrint ? "transparent" : "#000",
                                            }}
                                        >
                                            Relation With Primary
                                        </td>
                                        <td
                                            style={{
                                                minWidth: "700px",
                                                border: prePrint ? "none" : "2px solid #4e889b",
                                                padding: prePrint ? "16px" : "15px",
                                            }}
                                        >
                                            {cardData?.relation_with_primary}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="footer" style={{ opacity: prePrint ? 0 : 1 }}>
                            Office: NCC Complex, Opposite Post Office, Anushaktinagar
                            <br />
                            Email: asmc.dae@gmail.com; Phone: 2558 0497
                        </div>
                    </div>
                    <div
                        className="back-main"
                        ref={backRef}
                        style={{ backgroundColor: prePrint ? "transparent" : "#e5f4fd" }}
                    >
                        <div class="table-container">
                            <table>
                                <tr
                                    style={{
                                        opacity: prePrint ? 0 : 1,
                                        border: prePrint ? "none" : "2px solid #4e889b",
                                        padding: prePrint ? "2px" : "0px",
                                    }}
                                >
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Sr. No.
                                    </th>
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Sports Activities
                                    </th>
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Category Main / Sub
                                    </th>
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Valid Upto
                                    </th>
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Batch Name
                                    </th>
                                    <th
                                        style={{
                                            opacity: prePrint ? 0 : 1,
                                            border: prePrint ? "none" : "2px solid #4e889b",
                                            padding: prePrint ? "2px" : "0px",
                                        }}
                                    >
                                        Signature
                                    </th>
                                </tr>
                                {cardData?.bookings &&
                                    cardData?.bookings.map((obj, index) => {
                                        return (
                                            <tr>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                >
                                                    {index + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                >
                                                    <span>{obj?.activity}</span>
                                                    <span style={{ display: "block", fontSize: 35 }}>
                                                        {obj?.location ? `${obj?.location}` : ""}{" "}
                                                        {obj?.sublocation ? `(${obj?.sublocation})` : ""}
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                >
                                                    <span>{obj?.category}</span>
                                                    <span style={{ display: "block", fontSize: 35 }}>
                                                        {obj?.sub_category ? `(${obj?.sub_category})` : ""}
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                >
                                                    {obj?.valid_upto}
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                >
                                                    {obj?.batch_name}
                                                </td>
                                                <td
                                                    style={{
                                                        border: "2px solid #4e889b",
                                                    }}
                                                ></td>
                                            </tr>
                                        );
                                    })}
                            </table>
                        </div>
                    </div>
                </div>
            </DialogContent>
            {/* {cardData && ( */}
            <DialogActions>
                <Button onClick={close}>Close</Button>
                <Button onClick={onButtonClickFront}>Front Download</Button>
                <Button onClick={onButtonClickBack}>Back Download</Button>
            </DialogActions>
            {/* )} */}
        </Dialog>
    );
};
