import { useState } from "react";
import { Card, Box, CardContent, Chip, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import Input from "../../Common/Input";
import BasicSelect from "../../Common/Select";
import Button from "../../Common/Button";
import DatePickerComponent from "../../Common/DatePicker";
import { format, formatISO, parseISO } from "date-fns";
import * as yup from "yup";

const FormValidation = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    mobile: yup.string().required("Required"),
    dob: yup.string().required("Required"),
    chss_number: yup.string().required("Required"),
});

// Participant Card Component
export const ParticipantCard = ({ participant }) => {
    return (
        <Grid item xs={12} md={12}>
            <Card
                sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 2,
                    p: 2,
                    mx: "auto",
                    height: { xs: 150, md: 200 },
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                }}
            >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {participant?.name}
                        </Typography>

                        <Box
                            sx={{
                                mt: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Email: </Typography>
                                <Typography variant="body2">{participant?.email}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Mobile: </Typography>
                                <Typography variant="body2">{participant?.mobile || "Not provided"}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Gender: </Typography>
                                <Typography variant="body2">{participant?.gender || "Not provided"}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Dob: </Typography>
                                <Typography variant="body2">
                                    {participant?.dob
                                        ? format(parseISO(participant?.dob), "dd-MM-yyyy")
                                        : "Not provided"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">CHSS Number: </Typography>
                                <Typography variant="body2">{participant?.chss_number || "Not provided"}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};

// Modified ParticipantForm Component
export const ParticipantForm = ({ title, participant: initialData, onSave }) => {
    const formik = useFormik({
        initialValues: initialData || {
            name: "",
            email: "",
            gender: "Male",
            mobile: "",
            dob: "",
            chss_number: "",
        },
        validationSchema: FormValidation,
        onSubmit: (values, { setSubmitting }) => {
            onSave({
                ...values,
            });
            if (!initialData) formik.resetForm();
            setSubmitting(false);
        },
    });

    return (
        <Card variant="outlined" sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {title}
                </Typography>
            </Box>

            {initialData ? (
                <ParticipantCard participant={initialData} />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Input
                            id="name"
                            name="name"
                            label="Name *"
                            onChange={formik.handleChange("name")}
                            value={formik?.values?.name || ""}
                            error={Boolean(formik?.errors.name)}
                            helperText={formik?.errors.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            id="mobile"
                            name="mobile"
                            label="Mobile"
                            onChange={formik?.handleChange("mobile")}
                            value={formik?.values?.mobile || ""}
                            error={Boolean(formik?.errors.mobile)}
                            helperText={formik?.errors.mobile}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            id="email"
                            name="email"
                            label="Email"
                            onChange={formik?.handleChange("email")}
                            value={formik?.values?.email || ""}
                            error={Boolean(formik?.errors.email)}
                            helperText={formik?.errors.email}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BasicSelect
                            size="small"
                            value={formik?.values?.gender || "Male"}
                            onChange={formik?.handleChange("gender")}
                            displayEmpty
                            label="Gender"
                            name="gender"
                            id="gender"
                            items={[
                                { label: "Male", value: "Male" },
                                { label: "Female", value: "Female" },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePickerComponent
                            id={"dob"}
                            name={"dob"}
                            label="Date of Birth"
                            onChange={(val) => formik?.setFieldValue("dob", formatISO(val))}
                            value={formik?.values?.dob ? new Date(parseISO(formik?.values?.dob)) : null}
                            fullWidth
                            onBlur={formik?.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            id="chss_number"
                            name="chss_number"
                            label="CHSS / ID Card Number"
                            onChange={formik?.handleChange("chss_number")}
                            value={formik?.values?.chss_number || ""}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} gap={1}>
                        <Button type="submit" loading={formik.isSubmitting} onClick={formik.handleSubmit}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
};
