export const baseUrl = "https://api.asmcdae.in";
// export const baseUrl = 'http://localhost:7055';

export const loginUrl = "/auth/admin-login";

export const drawerWidth = 60;
export const expandDrawerWidth = 260;

export const fileTypes = ["jpeg", "jpg", "png", "gif"];
export const videoTypes = ["mp4"];

export const defaultPaginate = {
    pageNo: 0,
    limit: 10,
    sortBy: -1,
    sortField: "createdAt",
    keywords: "",
};

export const daysArray = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
];

export const eventSampleContent = `
<h3>Event Overview</h3>
<p>Welcome to the <strong>[Event Name]</strong>! Join us for an unforgettable experience filled with excitement, learning, and connection. This event is designed to cater to enthusiasts of all levels and provides an opportunity to engage with like-minded individuals.</p>

<hr />

<h3>Key Highlights</h3>
<ul>
    <li><strong>Engaging Activities:</strong> Participate in a variety of activities ranging from <em>[Activity 1]</em> to <em>[Activity 2]</em>, designed to keep you entertained and inspired.</li>
    <li><strong>Expert Sessions:</strong> Hear from industry experts, including <em>[Speaker Name(s)]</em>, who will share their knowledge and insights on <em>[Topics of Discussion]</em>.</li>
    <li><strong>Networking Opportunities:</strong> Meet and connect with fellow participants, professionals, and enthusiasts in the <em>[Field/Interest]</em>.</li>
    <li><strong>Exclusive Benefits:</strong> Enjoy access to <em>[Exclusive Perks, e.g., early entry, complimentary goodies, etc.]</em>.</li>
</ul>

<hr />

<h3>Event Schedule</h3>
<p><strong>Day 1:</strong></p>
<ul>
    <li>Registration: 9:00 AM</li>
    <li>Welcome Speech: 10:00 AM</li>
    <li>Session 1: <em>[Topic Name]</em> - 11:00 AM</li>
</ul>
<p><strong>Day 2:</strong></p>
<ul>
    <li>Workshops: <em>[Time Range]</em></li>
    <li>Networking Lunch: <em>[Time]</em></li>
    <li>Closing Remarks: <em>[Time]</em></li>
</ul>
<p><em>Note: A detailed schedule will be provided upon registration.</em></p>

<hr />

<h3>Venue Information</h3>
<p>📍 <strong>Location:</strong> [Venue Name], [Address, City, State]</p>
<p>🗓️ <strong>Date:</strong> [Event Date(s)]</p>
<p>🕒 <strong>Time:</strong> [Start Time] - [End Time]</p>

<hr />

<h3>Participation Guidelines</h3>
<ol>
    <li>Ensure you bring a valid ID for registration.</li>
    <li>Maintain a respectful and collaborative attitude throughout the event.</li>
    <li>Follow the event dress code: <em>[Dress Code]</em>.</li>
    <li>Pre-register for workshops to secure your spot.</li>
</ol>

<hr />

<h3>Contact Us</h3>
<p>For any queries, please contact us at:</p>
<p>📧 <strong>Email:</strong> [Event Email Address]</p>
<p>📞 <strong>Phone:</strong> [Contact Number]</p>

<p>We look forward to welcoming you to the <strong>[Event Name]</strong>!</p>
`;

export const sizeValues = [
    "5X-Small",
    "4X-Small",
    "3X-Small",
    "2X-Small",
    "X-Small",
    "Small",
    "Medium",
    "Large",
    "X-Large",
    "2X-Large",
    "3X-Large",
    "4X-Large",
    "5X-Large",
    "6X-Large",
    "7X-Large",
];
