import * as yup from "yup";

export const EventsValidation = yup.object().shape({
    images: yup.mixed().required("Required"),
    event_name: yup.mixed().required("Required"),
    location_id: yup.mixed().required("Required"),
    description: yup.mixed().required("Required"),
    event_start_date: yup.mixed().required("Required"),
    event_end_date: yup.mixed().required("Required"),
    event_start_time: yup.mixed().required("Required"),
    event_end_time: yup.mixed().required("Required"),

    registration_start_date: yup.mixed().required("Required"),
    registration_end_date: yup.mixed().required("Required"),
    registration_start_time: yup.mixed().required("Required"),
    registration_end_time: yup.mixed().required("Required"),

    event_type: yup.mixed().required("Required"),
    members_type: yup.mixed().required("Required"),
    players_limit: yup.mixed(),
    min_players_limit: yup.mixed(),
});

export const CategoryValidation = yup.object().shape({
    category_name: yup.mixed().required("Required"),
    start_age: yup.mixed(),
    end_age: yup.mixed(),
    male_allowed: yup.mixed(),
    female_allowed: yup.mixed(),
    distance: yup.mixed(),
    belts: yup.mixed(),
    members_fees: yup.mixed().required("Required"),
    non_members_fees: yup.mixed().required("Required"),
});
